import React from "react";
import "./App.scss";
import Header from "./components/Header/Header";
import Homepage from "./screen/Homepage/Homepage";
import { Route, Routes } from "react-router-dom";

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Homepage />} />
        {/* <Route path="/contact" element={<Contact />} />
        <Route path="/project" element={<Project />} /> */}
      </Routes>
    </>
  );
}

export default App;
