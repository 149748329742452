import React from "react";
import styles from "./Project.module.scss";
import splash from "../../assets/splash.png";
import chooseFriends from "../../assets/choosefriends.png";
import opportunity from "../../assets/opportunity.png";
import handiphone from "../../assets/handiphone.png";

const Project = () => {
  return (
    <div className={styles["project"]}>
      <div style={{ flex: 1 }} className={styles["project__direction"]}>
        <div className={styles["project__card"]}>
          <div className={styles["project__inline"]}>
            <img src={splash} alt="" height={250} />
            <img src={chooseFriends} alt="" height={250} />
            <img src={opportunity} alt="" height={250} />
          </div>
        </div>

        <div className={styles["project__texts-ehilapp"]}>
          <h1 style={{ color: "white" }}>Ehilapp</h1>
          <p style={{ color: "white" }}>
            <span style={{ fontWeight: "bold", color: "white" }}>Anno:</span> 2022
          </p>
          <p style={{ color: "white" }}>
            {" "}
            <span style={{ fontWeight: "bold", color: "white" }}>Azienda:</span> Rawfish
          </p>
          <p style={{ color: "white" }}>
            {" "}
            <span style={{ fontWeight: "bold", color: "white" }}>Tecnologia:</span> React Native
          </p>
          <p style={{ color: "white" }}>
            {" "}
            <span style={{ fontWeight: "bold", color: "white" }}>Cliente:</span> Caritas Diocesana
            Veronese e CISL-Verona
          </p>
          <p className={styles["project__desc-ehilapp"]}>
            E' un’app che ho realizzato assieme ai miei colleghi in
            <span style={{ fontWeight: "bold", color: "white", paddingLeft: 4, paddingRight: 4 }}>
              React Native
            </span>
            con lo scopo di aiutare i cittadini a trovare i bonus, o le opportunità più vicine a
            loro. Ogni utente può decidere quale seguire tra i 7 amici presenti, semplicemente
            accendendo o spegnendo la relativa icona. All’interno dell’app il cittadino sarà in
            grado di visionare le opportunità non solo dello Stato italiano, ma anche del proprio
            Comune di residenza.
          </p>
          <a style={{ color: "white", fontWeight: "bold" }} href="https://ehilapp.it/ ">
            App
          </a>
        </div>
      </div>

      <div style={{ flex: 1 }} className={styles["project__direction"]}>
        <div className={styles["project__card"]}>
          <div className={styles["project__inline"]}>
            <img style={{ borderRadius: 16 }} src={handiphone} alt="" height={290} />
          </div>
        </div>

        <div className={styles["project__texts-ehilapp"]}>
          <h1 style={{ color: "white" }}>Mondiali.it</h1>
          <p style={{ color: "white" }}>
            <span style={{ fontWeight: "bold", color: "white" }}>Anno:</span> 2024
          </p>
          <p style={{ color: "white" }}>
            {" "}
            <span style={{ fontWeight: "bold", color: "white" }}>Azienda:</span> Replatz
          </p>
          <p style={{ color: "white" }}>
            {" "}
            <span style={{ fontWeight: "bold", color: "white" }}>Tecnologia:</span> Wordpress
          </p>
          <p style={{ color: "white" }}>
            {" "}
            <span style={{ fontWeight: "bold", color: "white" }}>Cliente:</span> Replatz
          </p>
          <p className={styles["project__desc-ehilapp"]}>
            Mi sto occupando di aggiornare l'intero sito in wordpress e di creare i contenuti ovvero
            gli articoli delle varie discipline sportive. Successivamente curo anche i contenuti
            social delle varie piattaforme.
          </p>
          <a style={{ color: "white", fontWeight: "bold" }} href=" https://www.mondiali.it/">
            Sito
          </a>
        </div>
      </div>
    </div>
  );
};

export default Project;
